import React from "react";
import { concatClassNames as cn } from "@system42/core";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";

const Imprint = () => (
  <Layout title={"Imprint"}>
    <article>
      <HeaderTitle overline={"Userbrain"}>Imprint</HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <p>The service on this site is provided by:</p>
          <p>
            Userbrain GmbH
            <br />
            Frauengasse 7<br />
            8010 Graz
            <br />
            AUSTRIA
          </p>
          <p>
            Phone: +43 316 455065
            <br />
            Email: support@userbrain.com
          </p>
          <p>
            Register number: FN494380f / LG Graz
            <br />
            UID: ATU73466028
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Imprint;
